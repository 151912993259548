.receiptList {
  list-style-type: none;
  padding-inline: 0;
}

.filterInput {
  background-color: var(--offWhite);
  border: 2px solid var(--offWhite);
  border-radius: 1rem;
  height: 2rem;
  margin: 1rem 0;
  padding: 0.5rem 1rem;
}
