.articleContainer {
  background-color: var(--white);
  border-radius: 4px;
  container-name: article;
  container-type: inline-size;
  padding: 0.5rem 1rem;
}

.articleContainer:not(:last-of-type) {
  border-bottom: 1px solid var(--offWhite);
  margin-bottom: 0.5rem;
}

.article {
  column-gap: 1rem;
  display: grid;
  grid-template-areas:
    'checkbox'
    'articlelink'
    'titlename'
    'articlename';
  grid-template-columns: 1fr;
}

.checkbox {
  accent-color: var(--primary);
  grid-area: checkbox;
  justify-self: flex-start;
}

.articleLink {
  grid-area: articlelink;
}

.articleLink > a {
  text-decoration: none;
}

.titleName {
  grid-area: titlename;
}

.articleName {
  grid-area: articlename;
}

@container article (width >= 800px) {
  .article {
    grid-template-areas: 'checkbox articlelink titlename articlename';
    grid-template-columns: auto 1.2fr 2fr 3fr;
  }
}
