.wheel {
  animation: spin 1s infinite linear;
  border-left: 2px solid var(--primaryDark);
  border-radius: 50%;
  height: 1rem;
  width: 1rem;
}

.wheel.light {
  border-left: 2px solid var(--white);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
