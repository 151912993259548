@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.errorContainer {
  animation: fadeIn 0.5s;
  background-color: color-mix(in lch, var(--accent) 10%, var(--white));
  border: 1px solid var(--accent);
  border-radius: 0.5rem;
  color: var(--accent);
  padding: 1rem;
}
