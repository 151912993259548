.searchButton {
  align-items: center;
  aspect-ratio: 1 / 1;
  background-color: var(--primary);
  border: none;
  border-radius: 50%;
  box-shadow: var(--buttonBoxShadow);
  color: inherit;
  cursor: pointer;
  display: inline-flex;
  font-weight: var(--fontWeightLight);
  height: 48px;
  justify-content: center;
  position: absolute;
  right: 1rem;
  top: calc(3.75rem + var(--bannerOffset));
  z-index: 20;
}

.searchButton:active,
.searchButton:focus-visible,
.searchButton:hover {
  background-color: var(--primaryHover);
}

.searchButton > img {
  aspect-ratio: 1/1;
  height: 25px;
}

@media (width > 768px) {
  .searchButton {
    top: calc(5.75rem + var(--bannerOffset));
  }
}

@media (width > 1024px) {
  .searchButton {
    display: none;
  }
}

@media print {
  .searchButton {
    display: none;
  }
}
