.toggleContainer {
  display: inline-grid;
  isolation: isolate;
  margin: 0.25rem;
}

.toggleContainer > input {
  cursor: pointer;
  display: inherit;
  grid-area: 1 / 1;
  margin: 0;
  opacity: 0;
}

.toggleContainer > label {
  align-items: center;
  border: 1px solid var(--offWhite);
  border-radius: 1rem;
  gap: 0.5rem;
  grid-area: 1 / 1;
  padding: 0.25rem;
  white-space: nowrap;
}

.toggleContainer label.inactiveFilter {
  background-color: var(--offWhite);
  border-color: var(--offWhite);
  color: var(--primary);
  display: inline-flex;
}

.toggleContainer label.activeFilter {
  background-color: var(--primary);
  border-color: var(--primary);
  color: var(--white);
  display: none;
}

.toggleContainer.invalid label.activeFilter,
.toggleContainer.invalid label.inactiveFilter {
  background-color: var(--offWhite);
  border-color: var(--accent);
  color: var(--accent);
  display: inline-flex;
}

.toggleContainer > input:focus ~ label,
.toggleContainer > input:hover ~ label {
  outline: 1px solid var(--primary);
}

.toggleContainer.invalid > input:focus ~ label {
  outline: 1px solid var(--accent);
}

.toggleContainer > input:checked ~ label.inactiveFilter {
  display: none;
}

.toggleContainer > input:checked ~ label.activeFilter {
  display: inline-flex;
}
