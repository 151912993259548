.resultsList {
  list-style-type: none;
  margin: 1rem 0;
  padding: 0;
}

.resultItem {
  background-color: var(--offWhite);
  border-left: 8px solid var(--primary);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
}

.resultBanner {
  align-items: center;
  color: var(--primary);
  display: flex;
  flex-direction: row;
  font-weight: bold;
  justify-content: space-between;
}

.links {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.highlight em {
  font-style: normal;
  font-weight: bold;
}

.highlight p:not(:last-of-type)::after {
  content: "...";
  display: block;
}

.iacLink,
.iacLink:visited,
.irLink,
.irLink:visited {
  color: var(--primary);
  display: inline-block;
  flex-grow: 2;
  text-decoration: none;
}

.iacLink:hover,
.irLink:hover {
  color: var(--primaryDark);
}

.noResults {
  color: var(--grey);
  margin: 2rem;
  text-align: center;
}

