.settingsOpen {
  display: none;
}

.backLinkHidden {
  display: none;
}

.accountInfo {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-top: 1rem;
}

.greeting {
  font-size: 1.25rem;
}

.accountLine {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.settingsList {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
}

.settingsLink,
.settingsLink:visited {
  background-color: var(--offWhite);
  border-left: 8px solid var(--primary);
  border-radius: 4px;
  color: var(--black);
  height: 36px;
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  vertical-align: middle;
}

.settingsLink.settingsSelectedLink {
  background-color: var(--primary);
  color: var(--white);
  font-weight: bold;
}

@media (width > 1024px) {
  .dashboardWrapper {
    display: grid;
    gap: 3rem;
    grid-template-columns: 30% auto;
  }

  .settingsOpen {
    align-self: start;
    display: block;
    position: sticky;
    top: var(--widePaddingY);
  }

  .settingsBackLink {
    display: none;
  }
}
