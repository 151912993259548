.panelContent {
  --topMaskSize: 0px;
  --bottomMaskSize: 0px;

  flex-grow: 10;
  mask-image: linear-gradient(
    to bottom,
    transparent 0,
    var(--black) var(--topMaskSize, 0),
    var(--black) calc(100% - var(--bottomMaskSize, 0)),
    transparent 100%
  );
  overflow: auto;
  padding: 0 1rem;
  scrollbar-gutter: stable;
}

.panelContent.topOverflowing {
  --topMaskSize: 48px !important;
}

.panelContent.bottomOverflowing {
  --bottomMaskSize: 48px !important;
}
