.footer {
  background-color: var(--offWhite);
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: flex-start;
  padding: 1rem 2rem;
}

.footerLinks {
  --footerColumns: 1;

  display: grid;
  gap: 0.25rem 2rem;
  grid-template-columns: repeat(var(--footerColumns), 1fr);
  grid-template-rows: auto;
}

.footerLink,
.footerLink:visited {
  color: var(--primaryDark);
  text-decoration: none;
}

.footerLink:hover {
  background-color: var(--lightGrey);
  color: var(--primary);
}

@media (width > 768px) {
  .footerLinks {
    --footerColumns: 2;
  }
}

@media (width > 1024px) {
  .footer.sticky {
    bottom: 0;
    position: sticky;
  }
}

@media (width > 1200px) {
  .footerLinks {
    --footerColumns: 3;
  }
}

@media (width > 1600px) {
  .footerLinks {
    --footerColumns: 4;
  }
}

@media print {
  .footer {
    display: none;
  }
}
