.help {
  margin: 1rem 0;
}

.primaryHelp {
  margin: 1rem 0 0.5rem;
}

.helpfulLinks {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: flex-start;
  list-style-type: none;
  margin: 1rem 0 0;
  padding: 0;
}

.helpfulLink,
.helpfulLink:visited {
  background-color: var(--white);
  border: 1px solid var(--primaryDark);
  border-radius: 20px;
  color: var(--primaryDark);
  padding: 4px 24px;
  text-decoration: none;
  white-space: nowrap;
}

.helpfulLink:hover,
.helpfulLink:visited:hover {
  border-color: var(--primaryLight);
  color: var(--primaryLight);
}

.helpfulLink.primary,
.helpfulLink.primary:visited {
  background-color: var(--primaryDark);
  color: var(--white);
}

.helpfulLink.primary:hover,
.helpfulLink.primary:visited:hover {
  background-color: var(--primaryLight);
  border-color: var(--primaryLight);
}

@media (width > 1024px) {
  .helpfulLinks {
    flex-direction: row;
  }
}
