.dialogButtons {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 1rem;
  padding-right: 1.5rem;
}

@media (width < 400px) {
  .dialogButtons {
    flex-direction: column;
    justify-content: center;
  }
}

.confirmationForm {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.confirmationFormContent {
  flex-grow: 2;
  overflow: auto;
  padding-right: 1rem;
}

.confirmationFormContent > div {
  margin: 1rem 0 1rem 0.5rem;
}

.confirmationFormContent label {
  display: block;
}

.confirmationFormContent input {
  border: 2px solid var(--primary);
  border-radius: 1rem;
  height: 2rem;
  padding: 0 1rem;
}

.confirmationFormContent textarea {
  background-color: var(--offWhite);
  border: none;
  border-radius: 0.5rem;
  padding: 1rem;
  resize: none;
  width: 98%;
}

.confirmationFormContent > div.docSection {
  margin-top: 2rem;
}

.confirmationFormContent > div.dualInputs {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.submitter {
  color: var(--darkGrey);
  font-size: smaller;
}

.error {
  color: var(--danger);
  font-weight: bold;
}
