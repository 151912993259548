.backToTopButton {
  aspect-ratio: 1;
  background: var(--lightGrey);
  border: 0;
  border-radius: 50%;
  bottom: 1rem;
  color: white;
  margin-top: 2rem;
  opacity: 0;
  position: fixed;
  right: 0.5rem;
  transition: opacity 0.5s ease;
  width: 3rem;
  z-index: 10;
}

.backToTopButton.visible {
  opacity: 0.8;
}

.backToTopButton.visible:hover {
  background-color: var(--grey);
}

@media (width > 1024px) {
  .backToTopButton {
    right: 1rem;
  }
}

@media print {
  .backToTopButton {
    display: none;
  }
}
