.registerPageWrapper {
  margin: 0 auto;
  max-width: 72rem;
  position: relative;
}

.pdfWrapper {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

@media(width <= 768px) {
  .pdfWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: static;
  }
}

@media print {
  .pdfWrapper {
    display: none;
  }
}
