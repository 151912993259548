.uploadForm {
  background-color: var(--offWhite);
  border-left: 8px solid var(--primary);
  border-radius: 0.25rem;
  margin-top: 1rem;
  padding: 1rem 2rem;
}

.uploadForm label {
  display: block;
}

.titleSelection {
  margin: 1rem 0;
}

.inputRow,
.multiInputRow {
  margin: 1rem 0;
}

.multiInputRow {
  container-name: multipleinput;
  container-type: inline-size;
}

.multiInputs {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.multiInputs > *:first-child {
  width: 100%;
}

.requiredItemLabel {
  color: var(--danger);
  font-weight: bold;
}

.requiredItemLabel strong {
  background-color: var(--lightGrey);
}

@container multipleinput (width >= 750px) {
  .multiInputs {
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .multiInputs > *:first-child {
    flex-basis: 75%;
  }
}

.required {
  color: var(--danger);
  font-weight: bold;
}

.input,
.textarea {
  border: 2px solid var(--white);
  border-radius: 1rem;
  height: 2rem;
  padding: 0 1rem;
}

.inputBorder {
  border-color: var(--primary);
}

.textarea {
  height: unset;
  padding: 1rem;
  width: 100%;
}

.lsaControl {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: flex-start;
}

.lsaNumberDialog[open] {
  border: 0;
  border-radius: 1rem;
  box-shadow: var(--dialogBoxShadow);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: auto;
  max-height: 90dvh;
  outline: 0;
}

.lsaNumberDialog::backdrop {
  backdrop-filter: blur(2px);
  background: rgb(0 0 0 / 0.1);
}

.lsaNumberDialog input[type="radio"] {
  accent-color: var(--primary);
}

.modalInputRow {
  align-items: center;
  display: flex;
  gap: 0.5rem;
  justify-content: flex-start;
  margin: 0.5rem 0;
}

.dialogButtons {
  padding-top: 0.5rem;
  text-align: right;
}
