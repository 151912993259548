.documentsContainer {
  background-color: var(--primary);
  color: var(--white);
  padding: var(--mobilePadding);
  position: relative;
}

.documentGroups {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.docsLoadingContainer {
  min-height: 400px;
}

.documentsTitle {
  font-size: 26px;
}

.documentList {
  list-style-type: none;
  padding: 0;
}

.documentList li {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.documentList li a,
.documentList li a:visited {
  color: var(--white);
}

.documentList li a:hover {
  background-color: var(--primaryDark);
}

.loadingWrapper {
  min-height: 150px;
  position: relative;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

@media (width > 768px) {
  .documentsContainer {
    padding: var(--tabletPadding);
  }

  .docsLoadingContainer {
    min-height: 260px;
  }

  .documentsTitle {
    font-size: 30px;
    text-align: center;
  }

  .buttonContainer {
    display: block;
  }
}

@media (width > 1024px) {
  .documentsContainer {
    padding: var(--widePadding);
  }

  .documentsTitle {
    font-size: 35px;
  }

  .documentGroups {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: flex-start;
  }
}
