.panelWrapper {
  flex-grow: 1;
  padding: 0;
}

@media (width <= 1200px) {
  .panelWrapper {
    display: none;
  }

  .open {
    backdrop-filter: blur(2px);
    background-color: rgba(0 0 0 / 0.25);
    inset: 0;
    position: absolute;
    z-index: 100;
  }

  .open .panelWrapper {
    background-color: var(--white);
    border-radius: 1rem;
    box-shadow: var(--dialogBoxShadow);
    display: block;
    height: calc(100dvh - 7rem);
    margin: 6rem auto 2rem;
    max-width: 500px;
    opacity: 1;
    padding: 2rem 0.5rem;
    z-index: 101;
  }

  .open .panelWrapper .filterWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }

  .modalButtonContainer {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
}

.filterControls {
  align-content: start;
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  padding: 0;
}

.searchFacetsWrapper {
  display: none;
}

.filterControls.stack > * {
  grid-area: 1/1;
}

.filterToggler {
  display: none;
}

@media (width > 1200px) {
  .panelWrapper {
    height: calc(100dvh - 18rem - var(--bannerOffset));
  }

  .filterControls {
    opacity: 1;
    overflow: hidden;
    padding: 0;
    transition: opacity 0.3s ease-in-out;
  }

  .searchFacetsWrapper {
    display: block;
    margin-bottom: 1rem;
  }

  .filterToggler {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 0.5rem;
  }

  .modalButtonContainer {
    display: none;
  }
}
