.headerRow {
  align-items: center;
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  margin-right: 1rem;
  padding: 0.5rem 0;

  .headerControls {
    align-items: center;
    display: flex;
    gap: 0.5rem;

    input[type="checkbox"] {
      accent-color: var(--primary);
    }

    button {
      margin: 0 1rem;
      min-height: 28px;
    }
  }
}

.docList {
  list-style-type: none;
  margin: 0;
  padding-inline: 0;
}
