.signUpForm {
  margin: 1.5rem 0;
}

.privacyPolicyWrapper {
  display: flex;
  gap: 0.5rem;
  margin: 1.5rem 0;
}

.privacyPolicyCheck {
  accent-color: var(--primary);
  height: 1.25rem;
  width: 1.25rem;
}

.privacyPolicyButton {
  background: transparent;
  border: none;
  border-radius: 16px;
  color: var(--secondary);
  cursor: pointer;
  font-family: inherit;
  font-size: 1rem;
  padding: 0.25rem;
}

.privacyPolicyButton:hover {
  background-color: var(--offWhite);
}

.privacyPolicyDialog[open] {
  border: 0;
  border-radius: 1rem;
  box-shadow: var(--dialogBoxShadow);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: auto;
  max-height: 90dvh;
  outline: 0;
  padding: 0;
}

.privacyPolicyDialog[open]::backdrop {
  backdrop-filter: blur(2px);
  background: rgb(0 0 0 / 0.1);
}

.policyP {
  margin-bottom: 0.5rem;
}

.dialogHeader {
  padding: 1rem;
}

.dialogHeader h2 {
  color: var(--primary);
  font-size: 22px;
  line-height: 28px;
  margin: 0;
}

.dialogFooter {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
  padding: 1rem;
}

.formControls {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media (width > 576px) {
  .signUpForm {
    display: grid;
    grid-column-gap: 1.5rem;
    grid-template-areas:
      'email password'
      'privacy privacy'
      'controls controls';
    max-width: 800px;
  }

  .emailField {
    grid-area: email;
  }

  .passwordField {
    grid-area: password;
  }

  .formControls {
    flex-direction: row;
    grid-area: controls;
    justify-content: space-between;
    max-width: 900px;
  }

  .dialogFooter {
    display: flex;
    justify-content: flex-end;
  }

  .privacyPolicyDialog[open] {
    max-width: 500px;
  }
}
