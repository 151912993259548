.irDocumentContainer {
  background-color: var(--white);
  border-radius: 4px;
  container-name: irdocument;
  container-type: inline-size;
  padding: 0.5rem 1rem;
}

.irDocumentContainer:not(:last-of-type) {
  border-bottom: 1px solid var(--offWhite);
  margin-bottom: 0.5rem;
}

.irDocument {
  column-gap: 1rem;
  display: grid;
  grid-template-areas:
    'checkbox docname date'
    'doctype doctype doctype';
  grid-template-columns: auto 4fr 1fr;
}

.checkbox {
  accent-color: var(--primary);
  grid-area: checkbox;
}

.docName {
  align-self: center;
  grid-area: docname;
}

.docName > a {
  text-decoration: none;
}

.date {
  align-self: center;
  grid-area: date;
  text-align: right;
}

.docType {
  grid-area: doctype;
}

@container irdocument (width >= 800px) {
  .irDocument {
    grid-template-areas: 'checkbox docname doctype date';
    grid-template-columns: auto 3fr 3fr 1fr;
  }
}
