.stageControl {
  align-items: center;
  background: var(--white);
  display: flex;
  gap: 0.5rem;
  position: sticky;
  top: 0;
}

.docList {
  list-style-type: none;
  padding-inline: 0;
  margin: 0;
}
