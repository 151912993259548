.adminToggleButton {
  align-items: center;
  background-color: var(--white);
  border: 0;
  border-radius: 1rem;
  color: var(--primary);
  cursor: pointer;
  display: inline-flex;
  padding: 0 0.3rem;
}

.adminToggleButton[aria-pressed='true'] {
  color: var(--accent);
}
