.field {
  margin-top: 1rem;
  width: 100%;
}

.fieldLabelGroup {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.errorLabel {
  color: var(--accent);
  opacity: 1;
  transition: opacity 0.25s ease-in;
}

.field input,
.field textarea {
  background-color: var(--offWhite);
  border: 2px solid var(--offWhite);
  border-radius: 1rem;
  padding: 0 1rem;
  width: 100%;
}

.field input {
  height: 32px;
}

.field textarea {
  height: 150px;
  padding: 0.5rem 1rem;
  resize: none;
}

.field input:focus,
.field textarea:focus {
  background-color: var(--white);
}

.field input:read-only {
  color: var(--grey);
}

.field input:read-only:focus {
  background-color: var(--offWhite);
}

.field[data-visited='true'] input:invalid,
.field[data-visited='true'] textarea:invalid {
  border-color: var(--accent);
}

.field[data-visited='false'] .errorLabel {
  opacity: 0;
  visibility: hidden;
}

.passwordWrapper {
  align-items: center;
  display: flex;
  position: relative;
}

.showPasswordButton {
  background: transparent;
  border: 0;
  border-radius: 50%;
  font-size: 1.5rem;
  height: 26px;
  position: absolute;
  right: 0.25rem;
}

.showPasswordButton:focus-visible,
.showPasswordButton:hover {
  background: var(--white);
  outline: 0;
}
