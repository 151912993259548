:root {
  /* padding */
  --mobilePadding: 2rem 1rem;
  --tabletPadding: 2rem;
  --widePaddingY: 2rem;
  --widePadding: var(--widePaddingY) 5rem;
  --underlineOffset: 2px;
  --bannerOffset: 0rem;

  /* Misc reused vals */
  --buttonBoxShadow: 0 4px 4px rgb(153 153 153 / 0.75);
  --dialogBoxShadow: 0 8px 8px 4px rgb(153 153 153 / 0.75);

  /* theme props as placeholders so IDEs don't whine */
  --white: white;
  --offWhite: ghostwhite;
  --lightGrey: lightgrey;
  --grey: grey;
  --black: black;
  --primaryLight: lightblue;
  --primary: blue;
  --primaryDark: darkblue;
  --primaryHover: navy;
  --secondary: skyblue;
  --secondaryDark: dodgerblue;
  --accent: red;
  --bodyFont: arial;
  --danger: red;
  --headingFont: arial;
  --fontWeight: 400;
  --fontWeightLight: 400;
  --lineHeight: 1.6;
  --h1: 3rem;
  --h2: 2.2rem;
  --h3: 2rem;
  --h4: 1.6rem;
  --h5: 1.2rem;
  --h6: 1rem;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

html {
  font-size: 1rem;
}

body {
  background-color: var(--lightGrey);
  color: var(--black);
  font-family: var(--bodyFont);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: var(--fontWeight);
  line-height: var(--lineHeight);
  margin: 0;
  min-height: 100dvh;
  overscroll-behavior-y: none;
  padding: 0;
  scroll-behavior: smooth;
}

p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--headingFont);
  font-weight: var(--fontWeight);
  margin: 2rem 0;
}

h1 {
  font-size: var(--h1);
  margin-top: 0;
}

h2 {
  font-size: var(--h2);
}

h3 {
  font-size: var(--h3);
}

h4 {
  font-size: var(--h4);
}

h5 {
  font-size: var(--h5);
}

h6 {
  font-size: var(--h6);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  min-height: 100dvh;
}

input,
textarea {
  font-family: inherit;
  font-size: 1rem;
}

input:focus-visible {
  outline: 2px solid var(--primary);
}

/* Default link styling */
a,
a:visited {
  color: var(--secondary);
  text-decoration: underline;
  text-underline-offset: var(--underlineOffset);
}

a:hover {
  color: var(--primary);
}

/* End default link styling */

/* Buttons don't inherit font styles... */
button {
  font-family: inherit;
  font-size: inherit;
}

/* DatePicker custom style - BEM styles not jive with stylelint settings */
/* stylelint-disable */
.customDatePicker.react-datepicker {
  background-color: var(--offWhite);
  border: none;
  box-shadow: 0 8px 8px rgb(153 153 153 / 75%);
  font-family: var(--bodyFont);
  font-size: 1rem;
}

.customDatePicker .react-datepicker__navigation.react-datepicker__navigation--previous {
  border-right-color: var(--primary);
}

.customDatePicker .react-datepicker__navigation.react-datepicker__navigation--previous:hover {
  border-right-color: var(--primaryDark);
}

.customDatePicker .react-datepicker__navigation.react-datepicker__navigation--next {
  border-left-color: var(--primary);
}

.customDatePicker .react-datepicker__navigation.react-datepicker__navigation--next:hover {
  border-left-color: var(--primaryDark);
}

.customDatePicker .react-datepicker__header {
  background-color: var(--offWhite);
  border-bottom: none;
}

.customDatePicker .react-datepicker__day-name,
.customDatePicker .react-datepicker__header .react-datepicker__current-month {
  color: var(--primary);
}

.customDatePicker .react-datepicker__day,
.customDatePicker .react-datepicker__day-name {
  width: 35px;
}

.customDatePicker .react-datepicker__day:hover {
  background-color: var(--primaryLight);
  color: var(--offWhite);
}

.customDatePicker .react-datepicker__day.react-datepicker__day--selected {
  background-color: var(--primary);
  color: var(--offWhite);
}

.customDatePicker .react-datepicker__day.react-datepicker__day--keyboard-selected {
  background-color: var(--primaryLight);
}

.customDatePicker .react-datepicker__day--outside-month {
  color: var(--grey);
}

.customDatePicker .react-datepicker__triangle {
  display: none;
}
/* stylelint-enable */

/* DatePicker End */

*::-webkit-scrollbar {
  width: 1rem;
}

*::-webkit-scrollbar-track {
  background-color: var(--lightGrey);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 0.4rem;
}

@media print {
  /* stylelint-disable-next-line */
  .ReactQueryDevtools {
    display: none;
  }
}
