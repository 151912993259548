.draftList {
  list-style-type: none;
  padding-inline: 0;
}

.draftContainer {
  background-color: var(--white);
  border-radius: 4px;
  container-name: irdocument;
  container-type: inline-size;
  padding: 0.5rem 1rem;
}

.draftContainer:not(:last-of-type) {
  border-bottom: 1px solid var(--offWhite);
  margin-bottom: 0.5rem;
}

.draft {
  display: block;
}

.din {
  grid-area: din;
}

.link {
  grid-area: link;
  white-space: nowrap;
}

.doctype {
  grid-area: doctype;
}

@container irdocument (width >= 450px) {
  .draft {
    column-gap: 1rem;
    display: grid;
    grid-template-areas:
    'din link'
    'doctype doctype';
    grid-template-columns: auto min-content;
    margin: 0.5rem 0;
  }
}

@container irdocument (width >= 800px) {
  .draft {
    grid-template-areas: 'din doctype link';
    grid-template-columns: 1fr 2fr min-content;
  }
}
