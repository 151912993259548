.syncDialog {
  background-color: var(--white);
  border: none;
  border-radius: 1rem;
  box-shadow: var(--dialogBoxShadow);
  max-width: unset;
  padding: 0;
  width: 365px;
}

.syncDialog::backdrop {
  backdrop-filter: blur(2px);
  background: rgb(0 0 0 / 0.1);
}

.syncDialog h1 {
  background-color: var(--accent);
  color: var(--white);
  font-size: 1.5rem;
  padding: 1rem;
}

.syncDialogBody {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 1.25rem 1.25rem;
}

.buttonContainer {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: flex-end;
}

@media (width > 600px) {
  .syncDialog {
    width: 450px;
  }
}
