.buttonLink,
.buttonLink:visited {
  background: transparent;
  border: none;
  border-radius: 16px;
  color: var(--secondary);
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  line-height: normal;
  padding: 0.25rem 1rem;
  text-decoration: none;
}

.buttonLink:hover {
  background-color: var(--offWhite);
  color: var(--primary);
}

.backLink,
.backLink:visited {
  align-items: center;
  display: inline-flex;
  margin-bottom: 1rem;
  text-decoration: none;
}

.buttonLink.disabled {
  color: var(--grey);
  cursor: not-allowed;
}

.buttonLink.disabled:hover {
  background-color: unset;
  color: var(--grey);
}
