.agency {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0.25rem 0;
}

.agency > span {
  border-bottom: 1px solid var(--offWhite);
  padding: 0.25rem 0 0.5rem;
  width: calc(100% - 120px);
}
