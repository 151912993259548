.documentWrapper {
  --baseIndentation: 1em;
  --subItemIncrement: 0.5em;

  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-size: 16px;
  margin: 0 auto;
  max-width: 70em; /* recommended max width for reading */
}

.documentWrapper.print {
  font-size: 12px;
}

.documentWrapper > div,
.documentWrapper > div > strong {
  page-break-inside: avoid;
}

.documentWrapper h1 {
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-size: 1.25em;
  font-weight: bold;
  margin-bottom: 0.5em;
}

.documentWrapper .smallLeftIndent {
  padding-left: var(--baseIndentation);
}

.documentWrapper .leftIndent {
  text-indent: calc(2 * var(--baseIndentation));
}

.documentWrapper .center,
.documentWrapper .centerHeading {
  font-size: 1.25em;
  text-align: center;
}

.documentWrapper .rightAlign {
  text-align: right;
}

.documentWrapper .absPosition {
  position: absolute;
}

.documentWrapper .emphasis {
  font-style: italic;
}

.documentWrapper .indent1 {
  padding-left: calc(2 * var(--baseIndentation));
}

.documentWrapper .indent2 {
  padding-left: calc(3 * var(--baseIndentation));
}

.documentWrapper .indent3 {
  padding-left: calc(4 * var(--baseIndentation));
}

.documentWrapper .indent4 {
  padding-left: calc(5 * var(--baseIndentation));
}

.documentWrapper .indent5 {
  padding-left: calc(6 * var(--baseIndentation));
}

.documentWrapper > .subsection {
  margin-bottom: 1em;
  text-indent: calc(2 * var(--baseIndentation));
}

.documentWrapper .subsection + .subsection {
  margin-bottom: 0.5em;
}

.documentWrapper .subitem1 {
  margin-left: calc((2 * var(--baseIndentation)) + var(--subItemIncrement));
}

.documentWrapper .subitem2 {
  margin-left: calc((2 * var(--baseIndentation)) + (2 * var(--subItemIncrement)));
}

.documentWrapper .subitem3 {
  margin-left: calc((2 * var(--baseIndentation)) + (3 * var(--subItemIncrement)));
}

.documentWrapper .subitem4 {
  margin-left: calc((2 * var(--baseIndentation)) + (4 * var(--subItemIncrement)));
}

.documentWrapper .subitem5 {
  margin-left: calc((2 * var(--baseIndentation)) + (5 * var(--subItemIncrement)));
}

.documentWrapper .tableWrapper {
  margin-top: 0.25em;
  max-width: 100%;
  overflow: auto;
}

.documentWrapper .tableWrapper table {
  border-collapse: collapse;
}

.documentWrapper img {
  max-width: 100%;
}

.documentWrapper sub,
.documentWrapper sup {
  font-size: 0.7em;
}

.documentWrapper .emphasize {
  font-weight: bold;
}

.documentWrapper .spacer {
  padding-left: 3em;
}

.documentWrapper ol.toc {
  list-style: none;
}

.documentWrapper ol.toc li {
  display: flex;
}

.documentWrapper ol.toc li a {
  min-width: 15em;
}

.documentWrapper .documentPostedDate {
  font-style: italic;
}

.documentWrapper p {
  min-height: 1ex;
}

@media print {
  .documentWrapper {
    font-size: 12px;
  }
}
