.successDialog {
  background-color: var(--primary);
  border: 0;
  border-radius: 1.25rem;
  box-shadow: var(--dialogBoxShadow);
  color: var(--white);
  max-width: 25rem;
  padding: 1.5rem;
  text-align: center;
}

.successDialog::backdrop {
  backdrop-filter: blur(2px);
  background: rgb(255 255 255 / 0.1);
}

.successDialog p:first-of-type {
  margin-bottom: 1rem;
}

.closeDialogButton {
  background: transparent;
  border: 0;
  color: var(--white);
  cursor: pointer;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
}

.closeDialogButton:focus {
  outline: 0;
}

.formControls {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1rem 0;
}

.captcha {
  align-items: center;
  background-color: var(--offWhite);
  border-radius: 1.25rem;
  display: flex;
  flex-direction: row;
  padding: 0.5rem 1rem;
}

.submitButton {
  background-color: var(--primary);
  border: 0;
  border-radius: 1rem;
  box-shadow: var(--buttonBoxShadow);
  color: var(--white);
  cursor: pointer;
  padding: 0.5rem 1.5rem;
}

.submitButton:hover {
  background-color: var(--primaryDark);
}

@media (width > 576px) {
  .contactForm {
    display: grid;
    grid-column-gap: 1.5rem;
    grid-template-areas:
      'name email'
      'topic topic'
      'message message'
      'controls controls';
  }

  .nameField {
    grid-area: name;
  }

  .emailField {
    grid-area: email;
  }

  .topicField {
    grid-area: topic;
  }

  .messageField {
    grid-area: message;
  }

  .formControls {
    grid-area: controls;
    margin: 1.5rem 0;
  }
}

@media (width > 1024px) {
  .contactForm {
    grid-template-areas:
      'name message'
      'email message'
      'topic message'
      'controls controls';
    max-width: 1024px;
  }

  .messageField {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
  }

  .messageField textarea {
    height: 100%;
  }
}
