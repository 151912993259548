.controlContainer {
  background-color: var(--offWhite);
  border-radius: 0.25rem;
  margin-top: 2rem;
  padding: 1rem;
}

.buttonsContainer {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 1rem;
}
