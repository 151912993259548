.link,
.link:visited {
  align-items: center;
  background-color: var(--offWhite);
  border-radius: 1rem;
  color: var(--secondary);
  display: inline-flex;
  flex-direction: row;
  gap: 0.25rem;
  padding: 0.1rem 0.5rem;
  text-decoration: none;
}

.link > .next {
  padding-left: 0.75rem;
}

.link > .previous {
  padding-right: 0.75rem;
}

.link:hover {
  background-color: var(--lightGrey);
  color: var(--primaryDark);
}
