.articleLoadingContainer {
  min-height: 300px;
  position: relative;
}

.docContent:empty::after {
  content: 'HTML content for Title ' attr(data-title) ', Article ' attr(data-article)
    ' is not available.';
  display: block;
  font-family: var(--bodyFont);
  padding: 1rem 0;
}

.notFound {
  padding-top: 1rem;
}

@media (width > 768px) {
  .headerAndNav {
    background-color: var(--white);
    position: sticky;
    top: 16px;
    z-index: 10;
  }

  .headerAndNav::before {
    background-color: var(--white);
    content: '';
    display: block;
    height: 18px;
    position: absolute;
    top: -18px;
    width: 100%;
    z-index: 9;
  }
}

@media (width > 1160px) {
  .headerAndNav {
    top: 32px;
  }

  .headerAndNav::before {
    height: 2rem;
    top: -2rem;
  }
}

@media print {
  .headerAndNav {
    display: none;
  }
}
