.accordion {
  background-color: var(--offWhite);
  border-left: 8px solid var(--primary);
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;
  padding: 0 0.5rem 0 1rem;
}

.toggleButton {
  align-items: center;
  background-color: var(--offWhite);
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  display: flex;
  flex-basis: 100%;
  flex-direction: row;
  font-family: inherit;
  font-size: 1rem;
  gap: 1rem;
  justify-content: space-between;
  min-height: 3rem;
  padding: 0.5rem 1rem;
  text-align: left;
}

.toggleButton[disabled] {
  color: var(--black);
  cursor: default;
}

.toggleButtonIcon {
  color: var(--primary);
  min-width: 2rem;
  rotate: 0;
  transition: rotate 0.3s;
}

.toggleButton[aria-expanded='true'] .toggleButtonIcon {
  rotate: -180deg;
}

.contentWrapper {
  border-radius: 0 0 0.25rem 0.25rem;
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  transition: grid-template-rows 0.3s;
  visibility: hidden;
}

.content {
  min-height: 0;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.toggleButton[aria-expanded='true'] + .contentWrapper {
  grid-template-rows: 1fr;
  padding: 0 1rem;
  visibility: visible;
}

.toggleButton[aria-expanded='true'] + .contentWrapper .content {
  opacity: 1;
}
