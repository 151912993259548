.docCount {
  margin-bottom: 0.5rem;
}

.moreLink {
  margin: 1rem 0 1.5rem;
  text-align: center;

  a {
    text-decoration: none;
  }
}

.documentListTitle {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 1rem;
}
