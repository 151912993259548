.dashboardContent {
  min-height: 200px;
  position: relative;
}

.searchAndFilter {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  margin-top: 1rem;
}

.searchAndFilter > :first-child {
  flex-grow: 2;
}

.filterButton {
  align-items: center;
  display: inline-flex;
  height: 2rem;
  justify-content: center;
  padding: 0;
  width: 2rem;
}

.filtersDialog[open] {
  background-color: var(--white);
  border: none;
  border-radius: 0.5rem;
  box-shadow: var(--dialogBoxShadow);
  display: flex;
  flex-direction: column;
  margin: 6rem 0 auto;
  max-height: calc(100dvh - 7rem);
  max-width: 425px;
  padding: 1rem 0 1rem 1rem;
}

.filtersDialog[open]::backdrop {
  backdrop-filter: blur(2px);
  background: rgb(0 0 0 / 0.1);
}

.filtersContentHeader {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.filterAccentHeader {
  color: var(--primary);
}

.filterModalButtons {
  align-items: center;
  display: flex;
  gap: 0.5rem;
  margin-right: 1rem;
}

.closeFiltersButton {
  background: transparent;
  border: none;
  color: var(--primary);
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.filterControls {
  max-height: 410px;
}

.filterModalFooter {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
}

.dashboardMessage {
  color: var(--grey);
  display: flex;
  justify-content: center;
  margin: 5rem auto;
  max-width: 25rem;
  text-align: center;
}

.dateFilterText {
  color: var(--grey);
  margin-top: 0.5rem;
  text-align: right;
}

.updateFollowing {
  margin-top: 1rem;
  text-align: right;

  a {
    text-decoration: none;
  }
}

.lastVisited {
  color: var(--grey);
  margin-bottom: 1rem;
}

@media (width > 375px) {
  .filtersDialog[open] {
    margin: 6rem auto auto;
  }
}
