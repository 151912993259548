.defaultButton {
  background-color: var(--primary);
  border: 1px solid var(--primary);
  border-radius: 14px;
  color: var(--white);
  cursor: pointer;
  font-family: var(--bodyFont);
  height: 28px;
  padding: 0 1rem;
  vertical-align: middle;
}

.defaultButton:hover {
  background-color: var(--primaryDark);
}

.defaultButton.hollowButton {
  background-color: transparent;
  color: var(--primary);
}

.defaultButton.hollowButton:hover {
  border-color: var(--primaryDark);
  color: var(--primaryDark);
}

.defaultButton[disabled],
.defaultButton[disabled]:hover {
  background-color: var(--grey);
  border-color: var(--grey);
  cursor: not-allowed;
}

.defaultButton.hollowButton[disabled],
.defaultButton.hollowButton[disabled]:hover {
  background-color: transparent;
  border-color: var(--grey);
  color: var(--grey);
}

.defaultButton.invertedButton {
  background-color: var(--white);
  border-color: var(--white);
  color: var(--primary);
}

.defaultButton.invertedButton:hover {
  background-color: var(--offWhite);
  border-color: var(--offWhite);
  color: var(--primaryDark);
}

.defaultButton.hollowButton.invertedButton {
  background-color: transparent;
  color: var(--white);
}

.defaultButton.hollowButton.invertedButton:hover {
  background-color: transparent;
  color: var(--offWhite);
}

@media (width > 768px) {
  .defaultButton {
    border-radius: 16px;
    height: 32px;
  }
}
