.followButton {
  align-items: center;
  background-color: var(--offWhite);
  border: 0;
  border-radius: 1rem;
  color: var(--black);
  cursor: pointer;
  display: inline-flex;
  gap: 0.5rem;
  padding: 0.5rem 1rem 0.5rem 0.75rem;
}

.followButton:active,
.followButton:hover {
  background-color: var(--lightGrey);
}

.followButton[aria-pressed='true'] {
  background-color: var(--primary);
  color: var(--white);
}

.followButton[aria-pressed='true']:active,
.followButton[aria-pressed='true']:hover {
  background-color: var(--primaryDark);
}

.confirmationDialog,
.followDialog {
  border: none;
  border-radius: 1rem;
  box-shadow: var(--dialogBoxShadow);
  max-width: unset;
  padding: 1.25rem;
  width: 365px;
}

.confirmationDialog {
  background-color: var(--primary);
  color: var(--white);
}

.confirmationDialog::backdrop,
.followDialog::backdrop {
  backdrop-filter: blur(2px);
  background: rgb(0 0 0 / 0.1);
}

.dialogHeader {
  color: var(--primary);
}

.agencyName {
  font-size: 18px;
  margin: 1rem 0 1.5rem;
}

.buttonContainer {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: center;
  margin-top: 1.5rem;
}

.buttonContainer > button {
  width: 160px;
}

.closeButton {
  background: none;
  border: none;
  color: var(--primary);
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 15px;
}

.docTypeList {
  list-style-type: none;
  padding-inline-start: 0;
}

.documentTypeSelection {
  display: grid;
  isolation: isolate;
}

.documentTypeSelection:not(:last-of-type) {
  border-bottom: 1px solid var(--offWhite);
}

.documentTypeSelection > input {
  grid-area: 1 / 1;
  margin: 0;
  opacity: 0;
}

.documentTypeSelection > label {
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  grid-area: 1 / 1;
  height: 42px;
  justify-content: space-between;
  padding: 0 0.5rem;
  z-index: 2;
}

.documentTypeSelection > label:hover,
.documentTypeSelection > input:focus-visible + label {
  background-color: var(--offWhite);
}

.documentTypeSelection > input:not(:checked) + label > svg {
  display: none;
}

.documentTypeSelection > input:checked + label > svg {
  color: var(--primary);
  display: inline;
}

@media (width > 600px) {
  .followDialog,
  .confirmationDialog {
    width: 450px;
  }

  .buttonContainer > button {
    width: 180px;
  }
}
