.searchPage {
  align-items: stretch;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 0;
  padding-top: 0;
}

.searchAndTabs {
  background-color: var(--white);
  padding-top: 1rem;
  position: sticky;
  top: 0;
  z-index: 2;
}

.tabBar {
  display: none;
}

.searchContainer {
  padding: 1rem;
}

.searchContent {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.filters,
.results {
  position: relative;
}

.clearButton {
  border: none;
  height: unset;
}

.selectContainer {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;
  margin-top: 1rem;

  & > :first-child {
    flex-grow: 2;
  }

  & > div > div:first-of-type {
    border-radius: 1rem;
    margin: 0;
    padding: 0;
  }

  & > div > div:first-of-type:has(+ div) {
    border-radius: 1rem 1rem 0 0;
  }
}


@media (width > 1024px) {
  .searchAndTabs {
    padding-top: 2rem;
  }
}

@media (width > 1200px) {
  .selectContainer {
    display: none;
  }

  .tabBar {
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    justify-content: flex-start;
    margin-top: 1rem;

    button {
      align-items: center;
      background-color: var(--offWhite);
      border: none;
      border-radius: 0.25rem;
      cursor: pointer;
      display: flex;
      gap: 0.5rem;
      justify-content: space-between;
      padding: 0.25rem 0.8rem;
      white-space: nowrap;
    }


    button:hover {
      background-color: var(--primaryHover);
      border: none;
      color: var(--white);
    }

    button[disabled] {
      background-color: var(--primary);
      border: none;
      color: var(--white);
      cursor: initial;
      padding: 0.5rem 1rem;

      :first-child {
        color: var(--white);
      }
    }
  }

  .searchContent {
    align-items: stretch;
    flex-direction: row;
    gap: 2rem;
    justify-content: flex-start;
  }

  .filters {
    flex-shrink: 0;
    min-height: 300px;
    width: clamp(0rem, 33%, 30rem);
  }

  .results {
    flex-grow: 2;
    min-height: 300px;
  }
}
