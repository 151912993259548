.filterWrapper {
  align-items: center;
  display: grid;
  grid-template-columns: 2rem auto;
  margin: 0;
}

.filterIcon {
  grid-area: 1 / 1 / 1 / 1;
  margin-left: 0.5rem;
  z-index: 1;
}

.filterInput {
  accent-color: var(--primary);
  background-color: var(--offWhite);
  border: none;
  border-radius: 1rem;
  grid-area: 1 / 1 / 1 / 3;
  height: 28px;
  padding: 0 0.5rem 0 2rem;
}

.filterInput:focus {
  background-color: var(--white);
}

@media (width > 768px) {
  .filterInput {
    height: 32px;
  }
}
