.banner {
  align-items: flex-start;
  background-color: #e9d544;
  color: var(--primary);
  column-gap: 0.25rem;
  display: flex;
  flex-direction: row;
  font-weight: bold;
  padding: 0.5rem 1rem;

  .dismissButton {
    background-color: transparent;
    border: 0;
    color: var(--primary);
    cursor: pointer;
    display: inline-flex;
    font-size: 1.5rem;
    grid-area: button;
    margin: 0;
    padding: 0;
  }

  .message {
    a {
      color: var(--primary);
    }
  }
}
