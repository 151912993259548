.accent {
  container-name: accent-header;
  container-type: inline-size;
}

.header {
  line-height: 1.3;
  margin: 0 0 0.25rem;
  padding: 0 0 0.25rem;
  text-wrap: balance;
}

.header > span {
  font-size: 65%;
  margin-left: 1rem;
}

.underline {
  border-bottom: 2px solid var(--accent);
  margin: 0 0 0.5rem;
  width: 95%;
}

.underline.light {
  border-color: var(--offWhite);
}

h1.header {
  font-size: clamp(1.625rem, 1.4885rem + 0.5825vw, 2.1875rem);
}

h2.header {
  font-size: clamp(1.375rem, 1.2536rem + 0.5178vw, 1.875rem);
}

h3.header {
  font-size: clamp(1.125rem, 1.0732rem + 0.221vw, 1.25rem);
}

@media (width > 768px) {
  .underline {
    width: 70%;
  }
}

@media (width > 1024px) {
  .underline {
    width: 46.5%;
  }

  .underline.subtle {
    border-color: var(--offWhite);
  }

  @container accent-header (width < 960px) {
    .underline {
      width: 95%;
    }
  }
}
