.checkboxFilter {
  align-items: center;
  background-color: var(--offWhite);
  border: 1px solid var(--offWhite);
  border-radius: 1.5rem;
  color: black;
  display: flex;
  font-size: 1.25rem;
  gap: 0.5rem;
  justify-content: space-between;
  margin: 0;
  padding: 0.5rem 0.5rem 0.5rem 0.75rem;
  width: 100%;

  input {
    accent-color: var(--primary);
    aspect-ratio: 1;
    margin-left: 1rem;
    width: 1.25rem;
  }

  & > :first-child {
    display: flex;
  }
}
