.titlesLoadingWrapper {
  min-height: 300px;
}

.codeNav {
  margin-top: 1rem;
}

.headerContainer {
  z-index: 40;
}

.articleOpen .headerContainer,
.articleOpen .codeNav {
  display: none;
}

.codeNav > div:first-of-type {
  margin-top: 0;
}

.codeNav > div:last-of-type {
  margin-bottom: 0;
}

.headerContainer > div:nth-child(2) {
  max-width: fit-content;
}

.headerContainer > div > div:first-of-type {
  border-radius: 1rem;
  padding: 0 1rem;
}

.headerContainer > div > div:first-of-type:has(+ div) {
  border-radius: 1rem 1rem 0 0;
}

.landing {
  display: none;
}

@media (width > 768px) {
  :target {
    scroll-margin: calc(190px + var(--bannerOffset));
  }
}

@media (width > 1160px) {
  .codeWrapper {
    display: grid;
    gap: 2rem;
    grid-template: 'nav header' 2rem 'nav article' auto / max(30%, 20rem) auto;
  }

  .headerContainer,
  .articleOpen .headerContainer {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    grid-area: header;
    justify-content: space-between;
  }

  .headerContainer > *:first-child {
    flex-grow: 2;
  }

  .headerContainer > *:first-child > h1 {
    color: var(--primary);
    font-size: 30px;
  }

  .titlesLoadingWrapper {
    grid-area: nav;
    position: relative;
  }

  .article {
    grid-area: article;
  }

  .codeNav,
  .articleOpen .codeNav {
    display: block;
    grid-area: nav;
    margin-top: 0;
    max-height: calc(100dvh - 8.5rem - var(--bannerOffset));
    overflow: auto;
    position: sticky;
    top: var(--widePaddingY);
  }

  .landing {
    display: block;
  }

  :target {
    scroll-margin: calc(180px + var(--bannerOffset));
  }
}
