.azurePromptDialog {
  background-color: var(--primary);
  border: none;
  border-radius: 1rem;
  box-shadow: var(--dialogBoxShadow);
  color: var(--white);
  max-width: unset;
  padding: 1.25rem;
  width: 365px;
}

.azurePromptDialog::backdrop {
  backdrop-filter: blur(2px);
  background: rgb(0 0 0 / 0.1);
}

.buttonContainer {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: center;
  margin-top: 1.5rem;
}

.buttonContainer > button {
  width: 160px;
}

@media (width > 600px) {
  .azurePromptDialog {
    width: 450px;
  }

  .buttonContainer > button {
    width: 180px;
  }
}
