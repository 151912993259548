.itemWrapper {
  background-color: var(--white);
  padding: 0.5rem 1rem;
}

.itemDetail,
.itemHeader,
.itemLinks {
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  justify-content: flex-start;
}

.digest {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  margin: 0;
  max-width: 70rem;
  overflow: hidden;
  padding: 0.5rem 1rem 0;
}

.digest h1 {
  font-size: 1.25rem;
  margin-block-end: 0.25rem;
}

.digest :global(.spacer) {  /* stylelint-disable-line selector-pseudo-class-no-unknown */
  display: inline-block;
  margin: 0 1rem;
}

/* Remove the document digest's bolding as it overwhelms the bolding of the metadata */
.digest strong {
  font-weight: normal;
}

/* Sometimes, there's a large table in the digest, and we don't want to show it here */
.digest table {
  display: none;
}

.itemLinkGroup {
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
}

.itemDetailGroup {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-left: 1rem;
}

.itemDetail:last-of-type {
  padding-bottom: 1rem;
}

.documentDivider {
  border-color: var(--offWhite);
  border-style: solid;
  border-width: 2px;
  margin: 0.5rem 0.5rem 0 0;
}

@media (width > 576px) {
  .itemDetailGroup {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: flex-start;
  }

  .itemLinkGroup {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
}
