.optionButton {
  align-items: center;
  background: var(--offWhite);
  border: 1px solid var(--offWhite);
  border-radius: 1.5rem;
  cursor: pointer;
  display: flex;
  font-family: inherit;
  font-size: 1.25rem;
  justify-content: space-between;
  margin: 0;
  padding: 0.25rem 0.25rem 0.25rem 0.75rem;
  width: 100%;
}

.optionButton[disabled] {
  cursor: not-allowed;
}

.optionButtonIcon {
  min-width: 2rem;
  rotate: 0;
  transition: rotate 0.3s;
}

.options {
  border: 1px solid var(--offWhite);
  border-radius: 0 0 1.5rem 1.55rem;
  border-top: 0;
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  transition: grid-template-rows 0.3s;
  visibility: hidden;
}

.optionsContent {
  max-height: 500px;
  min-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: opacity 0.3s ease-in-out;
}

.optionButton[aria-expanded='true'] {
  background-color: var(--white);
  border-bottom: none;
  border-radius: 1.5rem 1.5rem 0 0;
}

.optionButton[aria-expanded='true'] .optionButtonIcon {
  rotate: -180deg;
}

.optionButton[aria-expanded='true'] + .options {
  grid-template-rows: 1fr;
  padding: 0 0.5rem 0.5rem;
  visibility: visible;
}

.stackToTop {
  background-color: var(--white);
  z-index: 100;
}

.stackToTop .optionsContent {
  max-height: calc(100dvh - 18rem)
}

.optionButton[aria-expanded='true'] + .options .optionsContent {
  opacity: 1;
}

.invalidButton {
  color: var(--accent);
  font-weight: bold;
}

@media (width > 1280px) {
  .stackToTop .optionsContent {
    max-height: calc(100dvh - 23rem - var(--bannerOffset));
  }
}
