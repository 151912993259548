.verifyForm {
  margin: 1.5rem 0;
}

.formControls {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
}

.verifyButton,
.resendButton {
  width: 100%;
}

.resendButton {
  background-color: var(--white);
  color: var(--primary);
}

.resendButton:hover {
  background-color: var(--offWhite);
  border-color: var(--primaryDark);
  color: var(--primaryDark);
}

@media (width > 576px) {
  .verifyForm {
    display: grid;
    grid-column-gap: 1.5rem;
    grid-template-areas:
      'email verification'
      '- controls';
    max-width: 800px;
  }

  .emailField {
    grid-area: email;
  }

  .verificationCodeField {
    grid-area: verification;
  }

  .formControls {
    grid-area: controls;
  }

  .verifyButton,
  .resendButton {
    width: unset;
  }
}
