.banner {
  background-color: var(--accent);
  color: var(--white);
  padding: 0.5rem 0.75rem;
  display: flex;
  justify-content: space-between;
}

.stagePicker {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

a.link {
  color: var(--white);
  text-decoration: none;
  display: flex;
  align-items: center;
  line-height: 0;
}

.label {
  font-weight: bold;
}
