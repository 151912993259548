.accordion,
.topLevelAccordion {
  background-color: var(--offWhite);
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;
  padding-left: 1rem;
}

.topLevelAccordion {
  background-color: var(--offWhite);
  border-left: 8px solid var(--primary);
  border-radius: 0.25rem;
  padding: 0 0.5rem;
}

.topLevelAccordion.onlyDocs.accordionOpen {
  padding-bottom: 1rem;
}

.toggleButton {
  align-items: center;
  background-color: var(--white);
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  display: flex;
  flex-basis: 100%;
  flex-direction: row;
  font-family: inherit;
  font-size: 1rem;
  gap: 1rem;
  justify-content: space-between;
  min-height: 3rem;
  padding: 0.5rem 1rem;
  text-align: left;
}

.toggleButton[disabled] {
  color: var(--black);
  cursor: default;
}

.topLevelAccordion > .toggleButton {
  background-color: var(--offWhite);
  padding: 0.5rem;
}

.toggleButtonIcon {
  color: var(--primary);
  min-width: 2rem;
  rotate: 0;
  transition: rotate 0.3s;
}

.toggleButton[aria-expanded='true'] .toggleButtonIcon {
  rotate: -180deg;
}

.toggleButton[aria-expanded='true'].directDocs {
  border-radius: 0.25rem 0.25rem 0 0;
}

.documents {
  border-radius: 0 0 0.25rem 0.25rem;
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  transition: grid-template-rows 0.3s;
  visibility: hidden;
}

.topLevelAccordion > .documents {
  border-radius: 0.25rem;
}

.topLevelAccordion.onlyDocs > .documents {
  margin-left: 1rem;
}

.documentsContent {
  min-height: 0;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.documentsContent > div:last-child > hr {
  display: none;
}

.toggleButton[aria-expanded='true'] + .documents {
  grid-template-rows: 1fr;
  visibility: visible;
}

.toggleButton[aria-expanded='true'] + .documents .documentsContent {
  opacity: 1;
}

.articleLink {
  background-color: var(--white);
  border-left: 0.25rem solid transparent;
  border-radius: 0.25rem;
  color: var(--secondary);
  display: block;
  margin: 0.5rem 0;
  padding: 0.5rem 1rem;
  text-decoration: none;
}

.articleLink:hover {
  background-color: color-mix(in lch, var(--white), var(--offWhite));
  border-left-color: color-mix(in lch, var(--accent) 75%, var(--black) 25%);
  color: var(--primary);
}

.articleLink.selected {
  border-left-color: var(--accent);
}
