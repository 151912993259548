.filtersContainer {
  container-name: filterscontainer;
  container-type: inline-size;
  margin-bottom: 1.25rem;
}

.filters {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.filters > *:first-child {
  width: 100%;
}

/* If the filterscontainer element is at least 600px wide, make it inline */
@container filterscontainer (width >= 600px) {
  .filters {
    align-items: center;
    flex-direction: row;
    gap: 1rem;
  }

  .filters > *:first-child {
    flex-grow: 2;
    width: unset;
  }
}
