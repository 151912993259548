.segmentedControl {
  align-items: center;
  background-color: var(--offWhite);
  border-radius: 14px;
  display: inline-grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  margin: 0 2px;
  padding: 0;
  width: auto;
}

.segmentedControl > span {
  display: grid;
  isolation: isolate;
  padding: 1px 3px;
}

.segmentedControl > span:not(:first-of-type) {
  border-left: 1px dashed rgb(0 0 0 / 0.1);
}

.segmentedControl > span > input {
  display: inherit;
  grid-area: 1 / 1;
  margin: 0;
  opacity: 0;
}

.segmentedControl > span > label {
  background-color: transparent;
  border: 1px solid var(--offWhite);
  border-radius: 14px;
  cursor: pointer;
  display: inline-block;
  grid-area: 1 / 1;
  padding: 0 4px;
  text-align: center;
  white-space: nowrap;
  width: 100%;
  z-index: 2;
}

.segmentedControl > span > input:disabled + label {
  color: var(--grey);
  cursor: not-allowed;
}

.segmentedControl > span > input:focus + label {
  outline: 1px solid var(--primary);
}

.segmentedControl > span > input:checked + label {
  background-color: white;
}

.segmentedControl > span:has(input:checked) {
  padding: 0 3px;
}

.segmentedControl > span:first-of-type:has(input:checked) {
  padding-left: 1px;
}

.segmentedControl > span:last-of-type:has(input:checked) {
  margin-left: 2px;
  padding-right: 1px;
}

@media (width > 768px) {
  .segmentedControl {
    border-radius: 16px;
  }

  .segmentedControl > span > label {
    border: 2px solid var(--offWhite);
    border-radius: 16px;
    height: 32px;
    padding: 0 8px;
  }
}
