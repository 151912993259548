.loadingContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 200px;
  position: relative;
}

@media (width > 1200px) {
  .searchContent {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    justify-content: stretch;

    & > :first-child {
      flex-shrink: 0;
      padding: 0;
      position: sticky;
      top: 200px;
      width: clamp(30rem, 33%, 40rem);
    }

    & > :last-child {
      min-height: 300px;
      width: 67%;
    }
  }
}
