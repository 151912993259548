.actionsOpinionsContainer {
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  gap: 2rem;
  min-height: 250px;
  padding: var(--mobilePadding);
}

.loadingWrapper {
  min-height: 150px;
  position: relative;
}

.actionsList {
  list-style-type: none;
  padding: 0;
}

.buttonContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-start;
}

a.olderButton {
  background-color: transparent;
  border: 1px solid var(--primaryDark);
  border-radius: 14px;
  color: var(--primaryDark);
  cursor: pointer;
  display: inline-block;
  font-family: var(--bodyFont);
  height: 28px;
  padding: 0 1rem;
  text-decoration: none;
  vertical-align: middle;
}

a.lsaLink {
  white-space: nowrap;
}

.listItem {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  gap: 0 1rem;
  justify-content: flex-start;
  margin-bottom: 0.25rem;
}

@media (width > 768px) {
  .actionsOpinionsContainer {
    padding: var(--tabletPadding);
  }

  a.olderButton {
    height: 32px;
    line-height: 1.9;
  }

  .buttonContainer {
    align-items: center;
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .listItem {
    flex-flow: row nowrap;
    margin-bottom: 0;
  }

  .listItem > :first-child {
    width: 90px;
  }
}

@media (width > 1024px) {
  .actionsOpinionsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: flex-start;
    padding: var(--widePadding);
  }
}
