.app {
  display: flex;
  flex-direction: column;
  height: 100dvh;
  justify-content: space-between;
}

.scrollable {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.main {
  align-items: stretch;
  background-color: var(--white);
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  flex-grow: 2;
  margin: 0 auto;
  width: min(100%, 1920px);
}

.headerWrapper {
  margin: 0;
  padding: 0;
}

@media print {
  .scrollable {
    overflow-y: unset;
  }

  .headerWrapper {
    display: none;
  }
}
