.irDocumentContainer {
  background-color: var(--white);
  border-radius: 4px;
  container-name: irdocument;
  container-type: inline-size;
  padding: 0.5rem 1rem;

  .emphasized {
    font-weight: bold;
  }
}

.irDocumentContainer:not(:last-of-type) {
  border-bottom: 1px solid var(--offWhite);
  margin-bottom: 0.5rem;
}

.irDocument {
  column-gap: 1rem;
  display: grid;
  grid-template-areas:
    'docname date'
    'doctype doctype';
  grid-template-columns: 4fr 1fr;
}

.docName {
  grid-area: docname;
}

.docName > a {
  text-decoration: none;
}

.time {
  grid-area: time;
  text-align: right;
  white-space: nowrap;
}

.date {
  grid-area: date;
  text-align: right;
}

.docType {
  grid-area: doctype;
}

@container irdocument (width >= 800px) {
  .irDocument {
    grid-template-areas: 'docname doctype date';
    grid-template-columns: 3fr 3fr 1fr;
  }
}
