.item {
  list-style-type: none;
  margin-right: 1rem;
}

.item > :first-child {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;
}

.item.filteredOut {
  height: 0;
  visibility: hidden;
}


.itemName:not(:has(.toggleButton)) {
  padding-left: 1.5rem;
}

.item:not(:last-of-type) > :first-child .itemName {
  flex-grow: 3;
}

.toggleButton {
  background-color: var(--white);
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-family: inherit;
  font-size: 1rem;
  gap: 0.5rem;
  justify-content: flex-start;
  padding: 0;
  text-align: left;
}

.toggleButtonIcon {
  color: var(--primary);
  min-width: 1rem;
  rotate: 0;
  transition: rotate 0.3s;
}

.toggleButton[aria-expanded='true'] .toggleButtonIcon {
  rotate: -180deg;
}

.toggleButton > span,
.childItemName {
  text-wrap: pretty;
}

.childItemListContainer {
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  transition: grid-template-rows 0.3s;
  visibility: hidden;
}

.childItemListContainer.open {
  grid-template-rows: 1fr;
  visibility: visible;
}

.childItemList {
  list-style-type: none;
  min-height: 0;
  opacity: 0;
  padding-inline-start: 2rem;
  transition: opacity 0.3s ease-in-out;

  li {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;
  }
}

.childItemListContainer.open > .childItemList {
  opacity: 1;
}
