.registerFilterForm {
  margin: 1rem 0 2rem;
}

.customRange input {
  background-color: var(--offWhite);
  border: none;
  border-radius: 14px;
  height: 28px;
  padding: 0 1rem;
  width: 100%;
}

.customRange input:disabled {
  color: var(--grey);
}

.customRangeWrapper {
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  transition: grid-template-rows 0.5s;
  visibility: hidden;
}

.customRangeWrapper.isOpen {
  grid-template-rows: 1fr;
  visibility: visible;
}

.customRangeWrapper .customRange {
  align-items: flex-end;
  display: flex;
  gap: 0.5rem;
  justify-content: flex-start;
  max-width: 500px;
  min-height: 0;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.customRangeWrapper.isOpen .customRange {
  margin-top: 0.5rem;
  opacity: 1;
  padding: 2px; /* allow for outline */
}

.customRange .submitButton {
  background-color: var(--primary);
  border: none;
  border-radius: 14px;
  cursor: pointer;
  height: 28px;
  padding: 0 0.5rem;
  vertical-align: middle;
}

.customRange .submitButton:hover {
  background-color: var(--primaryDark);
}

.dateContainer > div {
  width: 100%;
}

@media (width > 768px) {
  .customRange input,
  .customRange .submitButton {
    border-radius: 16px;
    height: 32px;
  }
}
