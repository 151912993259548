.signInOptions {
  margin-top: 2rem;
}

.signInOptions > div:first-child {
  margin-bottom: 2rem;
}

.signInOptions > div:last-child {
  flex-basis: 60%;
  width: 100%;
}

.formControls {
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin: 1rem 0;
  width: 100%;
}

.altSignIn {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media (width > 576px) {
  .loginForm {
    display: grid;
    grid-column-gap: 1.5rem;
    grid-template-areas:
      'email password'
      'controls controls';
    max-width: 800px;
  }

  .emailField {
    grid-area: email;
  }

  .passwordField {
    grid-area: password;
  }

  .formControls {
    grid-area: controls;
  }

  .altSignIn {
    flex-direction: row;
    justify-content: space-between;
    max-width: 800px;
  }
}

@media (width > 1200px) {
  .signInOptions {
    display: flex;
    flex-direction: row;
    gap: 6rem;
  }
}
