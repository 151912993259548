.about,
.code {
  background-color: var(--white);
  padding: var(--mobilePadding);
}

.rules {
  background-color: var(--offWhite);
  padding: var(--mobilePadding);
}

.code,
.rules {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.aboutHeader {
  border-bottom: 2px solid var(--accent);
  font-size: 26px;
  line-height: 32px;
}

.aboutSubHeader {
  border-bottom: 2px solid var(--accent);
  font-size: 22px;
  line-height: 28px;
}

.aboutImageContainer {
  align-items: center;
  background-color: var(--primary);
  display: flex;
  justify-content: center;
  max-height: 400px;
}

.aboutImageContainer > img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

@media (width > 768px) {
  .about,
  .code,
  .rules {
    padding: var(--tabletPadding);
  }

  .aboutImageContainer {
    height: 360px;
  }

  .aboutHeader {
    font-size: 30px;
    line-height: 40px;
  }

  .aboutSubHeader {
    font-size: 25px;
    line-height: 32px;
  }
}

@media (width > 1024px) {
  .about,
  .code,
  .rules {
    padding: var(--widePadding);
  }

  .code,
  .rules {
    flex-direction: row;
    gap: 2rem;
  }

  .aboutImageContainer {
    flex-shrink: 0;
    height: auto;
    width: calc(50% - 1rem);
  }

  .columnText {
    column-count: 2;
    column-gap: 2rem;
  }
}
