.success {
  color: var(--success);
}

.pending {
  color: var(--lightGrey);
}

.error {
  color: var(--danger);
}
