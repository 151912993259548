.upcomingContainer {
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  gap: 2rem;
  min-height: 250px;
  padding: var(--mobilePadding);
}

.upcomingLoadingContainer {
  min-height: 150px;
  position: relative;
}

.upcomingItems {
  list-style-type: none;
  padding: 0;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

@media (width > 768px) {
  .buttonContainer {
    display: block;
  }

  .upcomingContainer {
    padding: var(--tabletPadding);
  }
}

@media (width > 1024px) {
  .upcomingContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: flex-start;
    padding: var(--widePadding);
  }
}
