.tabBar {
  align-items: center;
  display: flex;
  justify-content: flex-start;

  & > button {
    min-height: 2.5rem;
    margin-bottom: -1px;
    background-color: var(--lightGrey);
    border: 1px solid var(--altGrey);
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    cursor: pointer;
  }
  & > button:hover {
    background-color: var(--lightGreyHover);
  }
  & > button.selected {
    color: var(--primaryDark);
    font-weight: bold;
    border-bottom: 1px solid var(--offWhite);
    background-color: inherit;
    cursor: auto;
  }
  & > button:not(:last-of-type) {
    border-right: 0;
  }
}
.tabContent {
  border: 1px solid var(--altGrey);
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  padding: 0.5rem;
}
