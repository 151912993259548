.statusDialog {
  border: none;
  border-radius: 1rem;
  box-shadow: var(--dialogBoxShadow);
  padding: 1.25rem;
}

.statusDialog::backdrop {
  backdrop-filter: blur(2px);
  background: rgb(0 0 0 / 0.1);
}

.dialogButtons {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 1rem;
}

.statusList {
  list-style-type: none;
  margin-block: 2rem;
  padding-inline: 2rem;
}

.statusItem {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: space-between;
  margin: 0.25rem 0;
}

.statusTitle {
  font-size: 1.5rem;
}
