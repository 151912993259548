.mainWrapper {
  padding: var(--mobilePadding);
}

@media (width > 768px) {
  .mainWrapper {
    padding: var(--tabletPadding);
  }
}

@media (width > 1024px) {
  .mainWrapper {
    padding: var(--widePadding);
  }
}
