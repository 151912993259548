/* stylelint-disable property-no-unknown */
[popover]:popover-open.tooltip {
  background-color: var(--white);
  border: 0;
  border-radius: 1rem;
  box-shadow: var(--dialogBoxShadow);
  color: var(--black);
  margin: 0.5rem 0 0;
  max-width: 400px;
  padding: 2rem 3rem 2rem 2rem;
  position-area: bottom span-left;
  position-try-fallbacks: flip-block, flip-inline;
}

.tooltipButton {
  align-self: normal;
  background: transparent;
  border: 0;
  border-radius: 1rem;
  color: var(--primary);
  cursor: pointer;
  height: 1rem;
  margin: 0;
  padding: 0;
}

.closeButton {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

