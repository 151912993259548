.searchInputWrapper {
  position: relative;
}

.searchInput {
  background-color: var(--white);
  border: 1px solid var(--primary);
  border-radius: 20px;
  height: 32px;
  padding: 0 3.5rem 0 1rem;
  width: 100%;
}

.searchInput:disabled {
  border-color: var(--grey);
}

.searchButton {
  background-color: var(--primary);
  border: none;
  border-radius: 20px;
  cursor: pointer;
  height: 32px;
  padding: 0 1rem;
  position: absolute;
  right: 0;
}

.searchButton:hover {
  background-color: var(--primaryDark);
}

.searchButton:disabled,
.searchButton:disabled:hover {
  background-color: var(--grey);
  cursor: not-allowed;
}

.searchButton > img {
  height: 20px;
}
