.headerRow {
  align-items: center;
  display: flex;
  gap: 0.5rem;
  justify-content: flex-start;
  padding: 0.5rem 0;

  input[type="checkbox"] {
    accent-color: var(--primary);
  }

  button {
    margin: 0 1rem;
    min-height: 28px;
  }
}

.docList {
  list-style-type: none;
  margin: 0;
  padding-inline: 0;
}
