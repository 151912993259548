.logo {
  display: flex;
}

.logo > img {
  width: 2rem;
}

.navLink,
.navLink:visited {
  align-items: flex-start;
  border-left: 5px solid transparent;
  color: var(--white);
  display: inline-flex;
  flex-direction: row;
  font-size: clamp(1rem, 0.9393rem + 0.2589vw, 1.25rem);
  padding: 0.5rem 0.75rem;
  text-decoration: none;
  white-space: nowrap;
  width: 100%;
}

.navLink:hover {
  background-color: var(--primaryDark);
  border-left-color: color-mix(in lch, var(--accent) 66%, var(--black) 34%);
  color: var(--white);
}

.mobileText {
  margin-left: 0.5rem;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.headerWrapper {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.header {
  align-items: center;
  background-color: var(--primary);
  display: flex;
  justify-content: space-between;
  padding: 0 0.8rem;
}

.headerToggle {
  display: none;
}

.logoTitle {
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: clamp(1.5rem, 1.4393rem + 0.2589vw, 1.75rem);
  gap: 1rem;
  justify-content: flex-start;
  line-height: 1.1;
  padding: 0.7rem 1rem 0.7rem 0;

  /* balanced text wrapping for browsers that support it */
  text-wrap: balance;
}

.shortTitle,
.longTitle {
  color: var(--offWhite);
  font-family: var(--headingFont);
  margin: 0;
}

.longTitle {
  display: none;
}

.navLinks {
  align-items: flex-start;
  background-color: var(--primary);
  border-top: 1px solid var(--primaryDark);
  display: none;
  flex-direction: column;
  padding: 1rem 0;
  position: absolute;
  right: 0;
  top: calc(3.4rem + var(--bannerOffset));
  width: 100%;
  z-index: 25;
}

.navLinks.open {
  animation: fadeIn ease 0.25s;
  display: flex;
}

.header.navOpen::before {
  backdrop-filter: blur(3px);
  background-color: rgb(0 0 0 / 0.1);
  content: '';
  height: 100dvh;
  inset: 0;
  position: absolute;
  top: calc(3.6rem + var(--bannerOffset));
  z-index: 25;
}

.navDrawerButton {
  background: transparent;
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
}

.navLink.activeNavLink {
  border-left: 5px solid var(--accent);
  font-weight: bold;
}

.searchIcon {
  aspect-ratio: 1;
  height: 1.5rem;
}

@media (width > 768px) {
  .navLinks,
  .header.navOpen::before {
    top: calc(5.4rem + var(--bannerOffset));
  }

  .logo > img {
    width: 4rem;
  }

  .longTitle {
    display: block;
  }

  .shortTitle {
    display: none;
  }
}

@media (width > 1024px) {
  .navLinks {
    align-items: stretch;
    align-self: stretch;
    border-top: none;
    display: flex;
    flex-direction: row;
    gap: 0;
    padding: 0;
    position: static;
    width: auto;
    z-index: 0;
  }

  .navLinks.closed,
  .navLinks.open {
    animation: none;
  }

  .header.navOpen::before {
    display: none;
  }

  .navDrawerButton {
    display: none;
  }

  .navLink {
    align-items: center;
    border-bottom: 5px solid transparent;
    border-left: none;
    border-top: 5px solid transparent;
    display: flex;
    justify-content: center;
    padding: 0 0.75rem;
    width: auto;
  }

  .navLink:hover {
    border-top-color: color-mix(in lch, var(--accent) 66%, var(--black) 34%);
  }

  .navLink.activeNavLink {
    border-left: none;
    border-top: 5px solid var(--accent);
  }

  .mobileText {
    display: none;
  }
}
