.topicHeader {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.toggleButton {
  background-color: var(--white);
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-family: inherit;
  font-size: 1rem;
  gap: 0.5rem;
  justify-content: flex-start;
  padding: 0;
  text-align: left;
}

.toggleButtonIcon {
  color: var(--primary);
  min-width: 1rem;
  rotate: 0;
  transition: rotate 0.3s;
}

.toggleButton[aria-expanded='true'] .toggleButtonIcon {
  rotate: -180deg;
}

.toggleButton > span {
  text-wrap: pretty;
}

.agencyListContainer {
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  transition: grid-template-rows 0.3s;
  visibility: hidden;
}

.agencyListContainer.open {
  grid-template-rows: 1fr;
  visibility: visible;
}

.agencyList {
  list-style-type: none;
  min-height: 0;
  opacity: 0;
  padding-inline-start: 2rem;
  transition: opacity 0.3s ease-in-out;
}

.agencyListContainer.open > .agencyList {
  opacity: 1;
}
