.listContainer {
  display: flex;
  flex-direction: column;
}

.noData {
  flex-grow: 10;
}

.listAndCount {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.selectionSummary {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.backButton,
.clearButton {
  align-self: flex-end;
  background: transparent;
  border: none;
  color: var(--primary);
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  padding: 0 0.5rem;
}

.clearButton[disabled],
.clearButton[disabled]:hover {
  background: transparent;
  color: var(--grey);
}

.backButton {
  margin-top: 0.5rem;
}

.backButton:hover,
.clearButton:hover {
  background: var(--offWhite);
  color: var(--primaryDark);
}

.filterOptions {
  display: none;
}

.filterOptions[open] {
  background-color: var(--white);
  border: none;
  border-radius: 0.5rem;
  box-shadow: var(--dialogBoxShadow);
  display: flex;
  flex-direction: column;
  height: calc(100dvh - 9rem);
  margin-top: 6rem;
  padding: 1rem 0 1rem 1rem;
  width: min(100vw, 550px);
}

.filterOptions[open]::backdrop {
  opacity: 0;
}

.filterHeader {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin: 0.25rem 0.5rem 0.5rem;
}

.filterHeaderTop {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.filterHeaderTop > *:first-child {
  display: none;
}

.filterHeaderTop > *:nth-child(2) {
  flex-grow: 3;
}

.listFilterFooter {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.summaryButton {
  align-items: center;
  background: var(--offWhite);
  border: 1px solid var(--offWhite);
  border-radius: 1.5rem;
  cursor: pointer;
  display: flex;
  font-family: inherit;
  font-size: 1.25rem;
  justify-content: space-between;
  margin: 0;
  padding: 0.25rem 0 0.25rem 0.75rem;
  width: 100%;
}

.summaryButton:disabled {
  cursor: not-allowed;
}

.summaryButton > span {
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 3rem);
}

.searchAccentHeader,
.inactiveSearchAccentHeader {
  color: var(--primary);
  font-size: 20px;
  line-height: 24px;
  margin: 0 0 0.75rem;
}

.inactiveSearchAccentHeader {
  border-color: var(--offWhite);
  color: var(--grey);
}

.inactiveSubHeader {
  color: var(--grey);
}

.modalHeader {
  color: var(--primary);
}

.doneButton {
  width: 12rem;
}

.inlinePanel {
  height: calc(100dvh - 23rem);
  margin-block: 0;
  padding: 0;
}

@media (width > 1280px) {
  .modalHeader > span {
    display: none;
  }

  .filterHeaderTop > *:first-child {
    flex-grow: 0;
  }

  .filterHeaderTop > *:nth-child(2) {
    flex-grow: 3;
    text-align: center;
  }

  .filterHeaderTop > .spacer {
    display: inline-block;
    width: 4rem;
  }

  .filterOptions[open] {
    height: calc(100dvh - 14rem - var(--bannerOffset));
    margin: 7rem auto;
    width: 768px;
  }

  .filterOptions[open]::backdrop {
    backdrop-filter: blur(2px);
    background: rgb(0 0 0 / 0.1);
    opacity: 1;
  }

  .listAndCount {
    padding: 1rem 0 1rem 0.25rem;
  }

  .inlinePanel {
    height: calc(100dvh - 30rem - var(--bannerOffset));
  }
}
