.articleMeta {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: space-between;
}

.titleName {
  font-size: 125%;
  margin: 0 0 1rem;
}

.articleName {
  font-size: 115%;
  margin: 0 0 1rem;
}

.hiddenDocument {
  display: none;
}

@media (width > 1160px) {
  .codeBackLink {
    display: none;
  }
}
