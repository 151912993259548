.agenciesLoadingWrapper {
  min-height: 200px;
  position: relative;
}

.allList,
.followingList {
  margin-top: 1rem;
}

.followingList > * {
  margin: 0 0.25rem 0.75rem;
}
