.notificationHeader {
  font-size: 18px;
  line-height: 1.333;
  margin: 1rem 0 0.5rem;
  padding: 0;
}

.frequencyText {
  margin-top: 1rem;
}
