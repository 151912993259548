.downloadButton {
  display: flex;
  justify-content: center;
  min-width: 4.3rem;
}

.downloadButton[disabled] {
  color: var(--black);
  cursor: wait;
}
