.fileUploader {
  margin: 2rem 0 1rem;
}

.fileSelector {
  visibility: hidden;
  width: 0;
}

.fileSelectorLabel {
  border-radius: 1.25rem 0.25rem 0.25rem 1.25rem;
  cursor: pointer;
  display: block;
}

.fileSelectorIcon {
  align-items: center;
  background-color: var(--primary);
  border-radius: 50%;
  color: var(--white);
  display: inline-flex;
  justify-content: center;
  margin-right: 1rem;
  padding: 0.75rem;
}

.fileSelectorLabel:hover .fileSelectorIcon {
  background-color: var(--primaryDark);
}

.fileSelectorLabel.target {
  background-color: var(--lightGrey);
}

.fileSelectorLabel.target .fileSelectorIcon {
  background-color: var(--success);
}

.fileSelectorLabel:has(+ input:user-invalid) .fileSelectorIcon {
  background-color: var(--accent);
}

.fileSelectorLabel:has(+ input:user-invalid):hover .fileSelectorIcon {
  background-color: var(--danger);
}


.deleteFileButton {
  background: transparent;
  border: none;
  cursor: pointer;
}

.deleteFileIcon {
  color: var(--danger);
}

.deleteFileButton:hover > .deleteFileIcon {
  color: var(--dangerLight);
}

.selectedFileList {
  list-style-type: none;
  margin-block: 0;
}

.selectedFile {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  justify-content: flex-start;
}
