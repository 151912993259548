.docReceiptContainer {
  container-name: docreceiptcontainer;
  container-type: inline-size;
}

.docReceipt {
  background-color: var(--offWhite);
  border-radius: 0.5rem;
  display: grid;
  gap: 0.5rem 1rem;
  grid-template-areas:
      'lsanumber intakelink'
      'titleandtype titleandtype'
      'submitdate submitdate';
  grid-template-columns: 2fr auto;
  margin: 0.5rem 0;
  padding: 0.75rem 1rem;
}

@container docreceiptcontainer (width >= 600px) {
  .docReceipt {
    gap: 0.5rem 1rem;
    grid-template-areas:
        'lsanumber intakelink'
        'titleandtype submitdate';
    grid-template-columns: 3fr 1fr;
  }

}

@container docreceiptcontainer (width >= 900px) {
  .docReceipt {
    grid-template-areas: 'lsanumber titleandtype submitdate intakelink';
    grid-template-columns: 1fr 4fr 2fr auto;
  }
}

.lsaNumber {
  grid-area: lsanumber;
}

.titleAndType {
  grid-area: titleandtype;
}

.submitDate {
  grid-area: submitdate;
}

.intakeLink {
  grid-area: intakelink;
}

.editButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.editDialog {
  border: none;
  border-radius: 1rem;
  box-shadow: var(--dialogBoxShadow);
  height: clamp(50%, 775px, 90dvh);
  padding: 1.5rem 0 1.5rem 1.5rem;
  width: 50rem;
}

.editDialog::backdrop {
  backdrop-filter: blur(2px);
  background: rgb(0 0 0 / 0.1);
}

