.heroContainer {
  align-items: center;
  backdrop-filter: blur(2px) brightness(0.4);
  background-image: url('assets/static/images/hero.png');
  background-size: cover;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 6rem 1rem;
  z-index: 10;
}

.heroTitle {
  color: var(--white);
  font-size: 2.5rem;
  line-height: 1.4;
  margin: 1rem 0;
  text-align: center;
}

.heroSearchForm {
  position: relative;
  width: min(80%, 960px);
}

.searchFilter {
  display: none;
  margin: 0;
}

.heroSearchInput {
  border: none;
  border-radius: 20px;
  height: 40px;
  padding: 0 1rem;
  width: 100%;
}

.searchButtons {
  align-items: center;
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  margin-top: 0.5rem;
}

.searchButtons > div > div:first-of-type {
  border-radius: 1rem;
  padding: 0 1rem;
  width: 12rem;
}

.searchButtons > div > div:first-of-type:has(+ div) {
  border-radius: 1rem 1rem 0 0;
}

.heroSearchInput.searchError::placeholder {
  color: var(--accent);
}

.heroSearchButton {
  background-color: var(--primaryDark);
  border: none;
  border-radius: 20px;
  cursor: pointer;
  height: 40px;
  padding: 0 1.5rem;
}

.heroSearchButton > img {
  height: 60%;
}

@media (width > 960px) {
  .heroSearchInput {
    padding: 0 17.5rem 0 1rem;
  }

  .searchButtons {
    margin-top: 0;
    position: absolute;
    right: 0;
    top: 0
  }
}
